import clsx from 'clsx';
import { useEffect, useState } from 'react';
import DOMPurify from 'isomorphic-dompurify';
import { IMerchandisingSmallBannerDescription } from '../organisms/MerchandisingSmallBanner/MerchandisingSmallBanner.types';

/**
 * @description MerchandisingSmallBannerDescription, has logic to render description section according to the screen sizes.
 * case-1 : if targetContent is not loaded then it will return skeleton loader
 * case-2 : if description available seperately for both mobile & desktop screens, then it will render according to screen size
 * case-3 : if only one description richText available, then it will show only one on all screens
 * @returns
 */
export const MerchandisingSmallBannerDescription = (props: IMerchandisingSmallBannerDescription) => {
  const {
    descriptionLong = '',
    descriptionLongMobile = '',
    customDescriptionClass = '',
    isTargetContentLoaded,
  } = props;

  const [descriptionHTML, setDescriptionHTML] = useState<string>('');
  const [descriptionMobileHTML, setDescriptionMobileHTML] = useState<string>('');

  useEffect(() => {
    if (isTargetContentLoaded) {
      setDescriptionHTML(DOMPurify.sanitize(descriptionLong, { RETURN_DOM: true })?.innerHTML);
      setDescriptionMobileHTML(DOMPurify.sanitize(descriptionLongMobile, { RETURN_DOM: true })?.innerHTML);
    }
  }, [isTargetContentLoaded, descriptionLong, descriptionLongMobile]);

  switch (true) {
    case !isTargetContentLoaded:
      return <div className="skeleton-loader mt-1 pb-4"></div>;
    case !!descriptionHTML && !!descriptionMobileHTML:
      /**
       * means description for both mobile & desktop html exist
       * if both descriptions exist, then we should show them according to the screen size...
       * descriptionMobileHTML - only for mobile
       * descriptionHTML - for tab & desktop
       */
      return (
        <>
          <div
            className={clsx(
              'merchandising-small-banner__description pt-2',
              'd-none d-sm-block t-font-alt-s',
              customDescriptionClass
            )}
            dangerouslySetInnerHTML={{ __html: descriptionHTML }}
          />
          <div
            className="merchandising-small-banner__description d-block d-sm-none pt-2"
            dangerouslySetInnerHTML={{ __html: descriptionMobileHTML }}
          />
        </>
      );
    case !!descriptionHTML && !descriptionMobileHTML:
      // means only descriptionLong exist
      return (
        <div
          className={clsx('merchandising-small-banner__description t-font-alt-s pt-2', customDescriptionClass)}
          dangerouslySetInnerHTML={{ __html: descriptionHTML }}
        />
      );
    default:
      return <></>;
  }
};
